import React, {FC} from 'react';
import {css} from '@emotion/core';
import loadable from '@loadable/component';
// @ts-expect-error
import ModalOverlay from '../components/ModalOverlay';

import MapquestLogoSmall from '../assets/svg/mapquest-logo-black-small.svg';
import LoadingCircles from '../assets/svg/loading-circles-blue.svg';

import {useExperiment} from '../../../common/experiments/experimentContext';
import {useCTA} from '../hooks/useCTA';
import MarketingLayout from '../components/MarketingLayout';
import {chromeExtensionLink} from '../constants';
import {ExperimentProps} from '../components/templates/type';

const experiments = {
    lp2: loadable<ExperimentProps>(() => import(/* webpackChunkName: "LP2" */ `../components/templates/1371/lp2`)),
    lp3: loadable<ExperimentProps>(() => import(/* webpackChunkName: "LP3" */ `../components/templates/skun446/lp3`)),
    '1371-blue-cta': loadable<ExperimentProps>(() =>
        import(/* webpackChunkName: "IndexBlueCTA" */ `../components/templates/1371/index-blue-cta`)
    ),
    '1371-green-cta': loadable<ExperimentProps>(() =>
        import(/* webpackChunkName: "IndexGreenCTA" */ `../components/templates/1371/index-green-cta`)
    ),
    default: loadable<ExperimentProps>(() =>
        import(/* webpackChunkName: "IndexGreenCTA" */ `../components/templates/1371/index-green-cta`)
    )
};

const isValidExperiment = (value: string): value is keyof typeof experiments => {
    return value in experiments;
};

const IndexPage: FC = () => {
    const {experimentId} = useExperiment();
    const {isModalOverlayVisible, onCtaClick} = useCTA({webStoreLink: chromeExtensionLink, isPrimary: true});
    const ExperimentComponent = isValidExperiment(experimentId) ? experiments[experimentId] : experiments.default;

    return (
        <MarketingLayout>
            <section
                className="top-container"
                css={css`
                    max-width: 100%;
                    overflow: hidden;
                    height: 100vh;
                    @media (max-height: 500px) {
                        overflow-y: scroll;
                    }
                `}
            >
                <ExperimentComponent onCtaClick={onCtaClick} />
            </section>
            <ModalOverlay isVisible={isModalOverlayVisible} maxWidth="300">
                <MapquestLogoSmall />
                <h2
                    css={css`
                        margin: 0.75rem 0;
                        font-weight: 400;
                        font-size: 16px;
                    `}
                >
                    Click &lsquo;Add to Chrome&lsquo; button to continue
                </h2>
                <LoadingCircles
                    css={css`
                        width: 100px;
                        height: 100px;
                    `}
                />
            </ModalOverlay>
        </MarketingLayout>
    );
};

export default IndexPage;
